@import "../../styles/mixins";

.tile {
  height: 50vh !important;

  @include for-small-tablet-devices {
    height: 50vh !important;
  }
  @include for-large-tablet-devices {
    height: 50vh !important;
  }
  @include for-medium-tablet-devices {
    height: 50vh !important;
  }
}