.cardContainer{
    border: 4px solid black; 
    padding: 5px;
}

.cardContainer h2 {
    font-size: 1.3rem; 
    text-align: center;
    font-weight: bold;
    margin: 0
}

.qualititySourceHeader{
    display: flex; 
    gap: 0.5rem;
    justify-content: space-between; 
    margin-top: 1rem; 
    margin-bottom: 1rem;
}

.qualititySourceItem {
    display: flex; 
    gap: 0.1rem;
    justify-content: center; 
    align-items: center;
}

.qualititySourceItem div {
    width: 2rem; 
    height: 1rem;
}

.qualititySourceItem p {
    font-weight: bolder; 
    font-size: 1rem;
}

.percentageContainer {
    display: flex; 
    flex-direction: column; 
    justify-content: space-between; 
    padding: 2.5rem 0;
}

.demoWrapper {
    background: white;
    box-sizing: border-box;
    resize: horizontal;
    border: 1px dashed;
    overflow: auto;
    max-width: 100%;
    height: calc(100vh - 16px);
}

@media screen and (max-width: 1100px) {
    .percentageContainer {
        padding: 1rem 0 2.5rem 0 !important;
    }
}
@media screen and (max-width: 1000px) {
    .percentageContainer {
       font-size: 0.8rem;
    }
}
@media screen and (max-width: 470px) {
    .qualititySourceItem p {
        font-size: 0.7rem;
    }
}
@media screen and (max-width: 382px) {
    .qualititySourceItem p {
        font-size: 0.6rem;
    }
}